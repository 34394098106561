import { useEffect, useRef, useState } from 'react'
import { useRect } from '@reach/rect'
import CarouselPagination from 'components/CarouselPagination'
import ChevronButton from 'components/ChevronButton'
import getColorStyle from 'lib/getColorStyle'
import useCarousel from 'hooks/useCarousel'
import Image from 'components/Image'
import Link from 'components/Link'
import cx from 'classnames'
import { getSpacing } from './helpers'

export default function CategoryModule({ module }) {
  const { heading, subTitle, background, mobileLayout, items } = module
  const { ref, prevProps, nextProps, pagiProps } = useCarousel({
    containScroll: 'trimSnaps',
  })
  const image1 = useRef()
  const image1Dom = useRect(image1)

  const image2 = useRef()
  const image2Dom = useRect(image2)

  const [paddingTopMobile, setPaddingTopMobile] = useState(module?.spacingTopMobile ?? 60)
  const [paddingBottomMobile, setPaddingBottomMobile] = useState(module?.spacingBottomMobile ?? 60)
  const [paddingBottomTablet, setPaddingBottomTablet] = useState(module?.spacingBottomTablet ?? 120)
  const [paddingTopTablet, setPaddingTopTablet] = useState(module?.spacingTopTablet ?? 90)
  const [paddingBottomDesktop, setPaddingBottomDesktop] = useState(module?.spacingBottomDesktop ?? 120)
  const [paddingTopDesktop, setPaddingTopDesktop] = useState(module?.spacingTopDesktop ?? 90)
  const [paddingTop, setPaddingTop] = useState(0)
  const [paddingBottom, setPaddingBottom] = useState(0)

  useEffect(() => {
    module?.spacingTopMobile !== undefined && setPaddingTopMobile(module.spacingTopMobile)
    module?.spacingBottomMobile !== undefined && setPaddingBottomMobile(module.spacingBottomMobile)
    module?.spacingBottomTablet !== undefined && setPaddingBottomTablet(module.spacingBottomTablet)
    module?.spacingTopTablet !== undefined && setPaddingTopTablet(module.spacingTopTablet)
    module?.spacingBottomDesktop !== undefined && setPaddingBottomDesktop(module.spacingBottomDesktop)
    module?.spacingTopDesktop !== undefined && setPaddingTopDesktop(module.spacingTopDesktop)
    setPaddingTop(getSpacing(paddingTopMobile, paddingTopTablet, paddingTopDesktop))
    setPaddingBottom(getSpacing(paddingBottomMobile, paddingBottomTablet, paddingBottomDesktop))
  }, [module])

  const {
    ref: ref1,
    prevProps: prevProps1,
    nextProps: nextProps1,
    pagiProps: pagiProps1,
  } = useCarousel({
    containScroll: 'trimSnaps',
  })

  const buildItems = (isMobile, isDesktop, ref) => {
    return items.map((item, _ix) => {
      const hasOnlySecondaryImage = !item.image
      return (
        <li
          key={item._key}
          className={cx('group flex-shrink-0', {
            'mx-4 w-160': isMobile,
            // 'w-50p s:w-25p': isMobile && mobileLayout === 1,
            'w-25p': isMobile && [2, 3].includes(mobileLayout),
            'w-50p px-6 m:w-25p l:w-20p ': isDesktop,
          })}
        >
          <div className="relative text-left">
            <div
              style={{ background: getColorStyle(item.background) }}
              className="relative w-full mb-18 m:mb-20 rounded-3 overflow-hidden pt-100p"
            >
              <div
                ref={!_ix ? ref : null}
                className="absolute flex top-0 w-full h-full left-0 justify-center items-center"
              >
                <div
                  className={cx('absolute h-full left-0 top-0 w-full', {
                    'group-hover:opacity-100 opacity-0': !hasOnlySecondaryImage,
                  })}
                >
                  <Image fill={true} placeholder={false} image={item.hoverImage} />
                </div>
                {!hasOnlySecondaryImage ? (
                  <div className="group-hover:opacity-0 w-full max-w-130 l:max-w-210">
                    <Image placeholder={false} image={item.image} />
                  </div>
                ) : null}
              </div>
            </div>
            <div className="group-hover:text-royal-blue underline font-medium text-16 l:text-20 leading-16 m:leading-33 text-navy-blue-default mb-9 m:mb-4">
              {item.title}
            </div>
            <p className="group-hover:text-royal-blue text-13 leading-18 m:leading-23 text-navy-blue-muted mr-18">
              {item.description}
            </p>
            {/* {item?.link?.title && <a className="group-hover:text-royal-blue text-13 l:text-16 leading-23 text-navy-blue-default underline">
              {' '}
              {item.link.title}{' '}
            </a>} */}
            <Link className="absolute left-0 top-0 w-full h-full z-1" link={item.link}></Link>
          </div>
        </li>
      )
    })
  }

  return (
    <div
      className="relative m:px-20 l:px-0 m:overflow-hidden home-categories"
      style={{ background: getColorStyle(background), paddingTop: `${paddingTop}px`, paddingBottom: `${paddingBottom}px` }}
    >
      <h2 className="text-26 text-center l:text-40 font-medium mb-15 m:mb-16 leading-28 m:leading-40">
        {heading}
      </h2>
      <p
        className={cx(
          'text-16 l:text-20 text-center text-navy-blue-default m:mb-42 leading-2-13 m:leading-28 w-11/12 m:w-full mx-auto',
          {
            'mb-28': mobileLayout === 3,
            'mb-20': [1, 2].includes(mobileLayout),
          },
        )}
      >
        {subTitle}
      </p>

      <div className="relative l:px-88 m:px-50">
        <div className="-mx-6 mr-30 m:-mr-16 m:-mx-16 m:overflow-hidden " ref={items?.length > 4 ? ref1 : null}>
          <ul
            className={cx('-mb-47 l:mb-0', {
              'hidden m:flex': mobileLayout !== 3,
              'flex flex-wrap': mobileLayout === 3,
              'm:flex-nowrap': items?.length > 4,
              'l:justify-center': items?.length < 4,
            })}
          >
            {buildItems(false, true, image1)}
          </ul>
        </div>

        {items?.length > 4 ? (
          <>
            <div
              className={cx('hidden m:block absolute -left-15 l:left-8 z-3 -mt-50 transform -translate-y-1/2')}
              style={{ top: '50%' }}
            >
              <ChevronButton className='bg-transparent border-none' chevronIconClassName='w-15' direction="left" label="Previous slide" {...prevProps1} />
            </div>
            <div
              className={cx('hidden m:block absolute -right-15 l:right-8 z-3 -mt-50 transform -translate-y-1/2')}
              style={{ top: '50%' }}
            >
              <ChevronButton className='bg-transparent border-none' chevronIconClassName='w-15' direction="right" label="Next slide" {...nextProps1} />
            </div>
          </>
        ) : null}
      </div>

      {/* Desktop Fader */}
      {items?.length > 4 ? (
        <>
          <div
            className={cx(
              'hidden m:block h-full w-40 l:w-60 xl:w-80 absolute top-0 left-0 z-2 bg-gradient-to-l from-transparent-white to-white transition pointer-events-none',
              {
                'opacity-0': prevProps1.disabled,
              },
            )}
          ></div>
          <div
            className={cx(
              'hidden m:block h-full w-40 l:w-60 xl:w-80 absolute top-0 right-0 z-2 bg-gradient-to-r from-transparent-white to-white transition pointer-events-none',
              {
                'opacity-0': nextProps1.disabled,
              },
            )}
          ></div>
        </>
      ) : null}

      {/* Mobile Fader */}
      <div
        className={cx(
          'hidden h-full w-40 l:w-60 xl:w-80 absolute top-0 left-0 z-2 bg-gradient-to-l from-transparent-white to-white transition pointer-events-none',
          {
            // 'opacity-0': prevProps.disabled || mobileLayout === 3,
          },
        )}
      ></div>
      <div
        className={cx(
          'm:hidden h-full w-40 l:w-60 xl:w-80 absolute top-0 right-0 z-2 bg-gradient-to-r from-transparent-white to-white transition pointer-events-none',
          {
            // 'opacity-0': nextProps.disabled || mobileLayout === 3,
          },
        )}
      ></div>

      {mobileLayout !== 3 ? (
        <>
          <div className="m:hidden relative no-scrollbar overflow-scroll">
            <div className="-mx-6 mr-20">
              <ul className="flex pl-20 pr-10">{buildItems(true, false, image2)}</ul>
            </div>
            {/* <div
              className={cx('absolute -left-10 z-1 transform -translate-y-1/2')}
              style={{ top: image2Dom?.height / 2 }}
            >
              <ChevronButton className='border-none bg-none' chevronIconClassName='w-15' direction="left" label="Previous slide" {...prevProps} />
            </div>
            <div
              className={cx('absolute -right-10 z-1 transform -translate-y-1/2')}
              style={{ top: image2Dom?.height / 2 }}
            >
              <ChevronButton className='border-none bg-none' chevronIconClassName='w-15' direction="right" label="Next slide" {...nextProps} />
            </div> */}
          </div>

          {/* <div className="m:hidden mt-55">
            <CarouselPagination count={items?.length} {...pagiProps} />
          </div> */}
        </>
      ) : null}
    </div>
  )
}
